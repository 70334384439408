<template>
    <div class="Pagedetails">
        <div class="bread-crumbs">
            <div class="inner pw">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>PPT模板</el-breadcrumb-item>
                    <el-breadcrumb-item>酒店和度假村演示ppt模板(PPTX)</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <article>
            <header class="entry-header">
                <h1 class="title-detail">
                    酒店和度假村演示ppt模板(PPTX)
                </h1>
                <div class="post-meta clearfix">
                    <div class="fr">
                        <span class="meta-author">C-Crystal / </span>
                        <em class="wk">2021.4.29</em>
                    </div>
                    <a href="" class="post-cate">
                        <em class="cate-tag-icon tag-213">
                            PPT模板
                        </em>
                    </a>
                </div>
            </header>
        </article>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>
@import './../../styles/Page/Pagedetails.css';
</style>